import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Category } from "../../models/category";
import { Product } from "../../models/product";
import getProductsInfo from "../../services/get-products-info";
import BudgetButton from "../budget-button";
import Select from "../select-category";
import Title from "../title";

function ProductsList() {
  const [value, setValue] = useState<string>("all");
  const [categoryList, setCategoryList] = useState<Category[]>([]);
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState<number>(0);
  const [renderedCategories, setRenderedCategories] = useState<Category[]>([]);

  const categoriesPerPage = 1;

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target) {
      setValue(e.target.value);
    }
  };

  useEffect(() => {
    async function fetchData() {
      const renderProducts = await getProductsInfo();

      renderProducts.categories.sort((a, b) => a.name.localeCompare(b.name));

      renderProducts.categories.forEach((category) => {
        category.groups.forEach((group) => {
          group.products.sort((a, b) => a.name.localeCompare(b.name));
        });
      });

      setCategoryList(renderProducts.categories);

      setRenderedCategories(
        renderProducts.categories.slice(0, categoriesPerPage),
      );
    }
    fetchData();
  }, []);

  const filteredProducts = categoryList.filter(
    (category) => value.toLowerCase() === "all" || category.name === value,
  );

  const handleLoadMore = () => {
    const nextCategoryIndex = currentCategoryIndex + categoriesPerPage;

    if (nextCategoryIndex < categoryList.length) {
      setCurrentCategoryIndex(nextCategoryIndex);

      setRenderedCategories((prevCategories) => [
        ...prevCategories,
        ...categoryList.slice(
          nextCategoryIndex,
          nextCategoryIndex + categoriesPerPage,
        ),
      ]);
    }
  };

  return (
    <section>
      <Title title="produtos" />
      <PageContent>
        <Select value={value} onChange={handleSelectChange} />
        {value.toLowerCase() === "all" ? (
          <>
            {renderedCategories.map((category) => (
              <ProductsContainer>
                <Title title={category.name} />
                <Products>
                  {category.groups
                    .filter((group) => group.active)
                    .map((group) => (
                      <>
                        {group.products
                          .filter((product: Product) => product.active)
                          // .sort((a: { name: string }, b: { name: string }) =>
                          //   a.name.localeCompare(b.name),
                          // )
                          .map((product: Product) => (
                            <ProductsCard key={product.name}>
                              <Link to={`/produtos/${product.id}`}>
                                <img
                                  src={product.image}
                                  alt={`foto do produto ${product.name}`}
                                />
                              </Link>
                              <h3>{group.name}</h3>
                              <p className="product-subtitle">{product.name}</p>
                              <p className="summary">{product.summary}</p>
                              <div className="buttons">
                                <BudgetButton product={product} />
                                <DetailsLink to={`/produtos/${product.id}`}>
                                  Saiba mais
                                </DetailsLink>
                              </div>
                            </ProductsCard>
                          ))}
                      </>
                    ))}
                </Products>
              </ProductsContainer>
            ))}
            {currentCategoryIndex + categoriesPerPage <
              filteredProducts.length && (
              <LoadMoreButton onClick={handleLoadMore}>
                Carregar mais
              </LoadMoreButton>
            )}
          </>
        ) : (
          <>
            {filteredProducts.map((category) => (
              <ProductsContainer>
                <Title title={category.name} />
                <Products>
                  {category.groups
                    .filter((group) => group.active)
                    .map((group) => (
                      <>
                        {group.products
                          .filter((product: Product) => product.active)
                          // .sort((a: { name: string }, b: { name: string }) =>
                          //   a.name.localeCompare(b.name),
                          // )
                          .map((product: Product) => (
                            <ProductsCard key={product.name}>
                              <Link to={`/produtos/${product.id}`}>
                                <img
                                  src={product.image}
                                  alt={`foto do produto ${product.name}`}
                                />
                              </Link>
                              <h3>{group.name}</h3>
                              <p className="product-subtitle">{product.name}</p>
                              <p className="summary">{product.summary}</p>
                              <div className="buttons">
                                <BudgetButton product={product} />
                                <DetailsLink to={`/produtos/${product.id}`}>
                                  Saiba mais
                                </DetailsLink>
                              </div>
                            </ProductsCard>
                          ))}
                      </>
                    ))}
                </Products>
              </ProductsContainer>
            ))}
          </>
        )}
      </PageContent>
    </section>
  );
}

export default ProductsList;

const PageContent = styled.div`
  margin: 20px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProductsContainer = styled.div`
  width: 100%;

  .title-component {
    margin: 0;
    margin-top: 20px;
  }

  .title-component h2 {
    font-size: 16px;
    margin: 0;
  }

  hr {
    margin: 0 0 10px 10px;
  }
`;

const Products = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 250px));
  grid-auto-rows: 475px;
  justify-content: space-between;
  gap: 30px;

  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const ProductsCard = styled.div`
  background-color: var(--background-card);
  border: solid 1px #000000;
  border-radius: 10px;
  width: 250px;
  min-height: 475px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin-bottom: 30px;

  img {
    width: 100%;
    height: 220px;
    margin: 5px;
    object-fit: contain;
    align-self: center;
    border-radius: 10px;
    background-color: #ffffff;
  }

  h3 {
    color: var(--text-highlight);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
  }

  .product-subtitle {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
  }

  .summary {
    font-size: 14px;
    font-weight: 300;
    text-align: justify;
    margin: auto 0;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
  }
`;

const DetailsLink = styled(Link)`
  background-color: var(--text-highlight);
  color: #ffffff;
  width: 80px;
  padding: 8px 5px;
  border-radius: 8px;
  font-weight: 300;
  font-size: 12px;
  text-align: center;

  &:hover {
    scale: 1.05;
    transition: 0.2s ease-in-out;
  }
`;

const LoadMoreButton = styled.button`
  margin-top: 20px;
  padding: 10px;
  background-color: var(--background-button);
  color: #ffffff;
  border: none;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    scale: 1.05;
    transition: 0.2s ease-in-out;
  }
`;
