import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { NewsItem } from "../../models/newsItem";
import getNewsInfos from "../../services/get-news-infos";
import Title from "../title";

function NewsDetails() {
  const [news, setNews] = useState<NewsItem>({
    id: 0,
    title: "",
    date: "",
    image: "",
    summary: "",
    details: "",
    additionalInfo: {
      local: "",
      town: "",
      date: "",
      time: "",
      stand: "",
      segment: "",
      website: "",
    },
  });

  const { id } = useParams();

  useEffect(() => {
    async function fetchData() {
      const newsInfo = await getNewsInfos();
      const selectedNews = newsInfo.data.find(
        (item: NewsItem) => item.id === Number(id),
      );

      if (selectedNews) {
        setNews(selectedNews);
      }
    }
    fetchData();
  }, [id]);

  const newsParagraphs = news.details.split("*");

  return (
    <section>
      <Title title={news.title} />
      <NewsContainer>
        <ImgContainer>
          <Img src={news.image} alt="imagem da notícia" />
          <NewsLink to="/noticias"> Voltar para notícias </NewsLink>
        </ImgContainer>

        <TextContainer>
          <DetailsContainer>
            {newsParagraphs.map((paragraph) => (
              <p>{paragraph}</p>
            ))}
          </DetailsContainer>

          {news.additionalInfo != null ? (
            <div>
              <p>
                <strong>Local: </strong>
                {news.additionalInfo.local}
              </p>
              <p>
                <strong>Cidade: </strong>
                {news.additionalInfo.town}
              </p>
              <p>
                <strong>Data: </strong>
                {news.additionalInfo.date}
              </p>
              <p>
                <strong>Horário: </strong>
                {news.additionalInfo.time}
              </p>
              <p>
                <strong>Estande: </strong>
                {news.additionalInfo.stand}
              </p>
              <p>
                <strong>Segmento: </strong>
                {news.additionalInfo.segment}
              </p>
              <a
                href={news.additionalInfo.website}
                target="_blank"
                rel="noreferrer"
              >
                <strong>Site: </strong>
                {news.additionalInfo.website}
              </a>
            </div>
          ) : (
            <p />
          )}
        </TextContainer>
      </NewsContainer>
    </section>
  );
}

export default NewsDetails;

const NewsContainer = styled.div`
  margin: 20px 50px;
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin: 20px;
  }
`;

const ImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Img = styled.img`
  width: 180px;
  height: 150px;
  border: solid 1px var(--primary-color);
  border-radius: 10px;
  object-fit: contain;
  margin-bottom: 20px;
`;

const NewsLink = styled(Link)`
  background-color: var(--text-highlight);
  color: #ffffff;
  width: 80%;
  padding: 8px;
  border-radius: 15px;
  font-weight: 300;
  font-size: 14px;
  text-align: center;

  &:hover {
    scale: 1.05;
    transition: 0.2s ease-in-out;
  }
`;

const TextContainer = styled.div`
  line-height: 25px;
  margin: 0 40px;

  strong {
    font-weight: 500;
  }

  a:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 30px;
  }
`;

const DetailsContainer = styled.div`
  margin-bottom: 30px;
  text-align: justify;
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: 10px;
  }
`;
