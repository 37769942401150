import styled from "styled-components";
import multitec from "../../assets/fachada-multitec.jpg";
import Title from "../title";

function CompanyHistory() {
  return (
    <section>
      <Title title="nossa história" />
      <PageContent>
        <ImgContainer>
          <Img src={multitec} alt="Fachada do prédio" />
        </ImgContainer>

        <Text>
          Fundada em julho de 1993, a Multitec iniciou suas atividades com o
          propósito de fornecer serviços de venda e manutenção em balanças. Ao
          longo dos anos, experimentou um crescimento contínuo e diversificou
          seu portfólio, expandindo para oferecer uma ampla gama de equipamentos
          e acessórios destinados a laboratórios.
          <br />
          <br />
          Desde janeiro de 1999, a Multitec está estrategicamente localizada no
          centro de Canoas/RS, ocupando um edifício próprio de 500m². A empresa
          conta atualmente com uma equipe de profissionais altamente
          qualificados, dedicados à prestação de serviços e à comercialização de
          equipamentos para laboratório.
          <br />
          <br />
          A busca incessante pela excelência no atendimento aos clientes levou a
          Multitec a investir no programa de Qualidade ISO, obtendo a
          certificação em dezembro de 2000. Em agosto de 2014, o reconhecimento
          da competência técnica do Laboratório de Calibração foi oficializado
          pela Rede Metrológica RS, por meio da concessão do Certificado de
          Reconhecimento de Competência Técnica, referente à implantação da
          norma ABNT NBR ISO/IEC 17.025/2005.
          <br />
          <br />
          Com o apoio dedicado de seus colaboradores e parceiros fornecedores, a
          Multitec está empenhada em atender às necessidades de seus clientes,
          assegurando qualidade em todos os aspectos, desde a fase de
          comercialização até o suporte pós-venda. O compromisso da empresa
          reside em oferecer soluções de excelência, refletindo o padrão de
          qualidade que a define no mercado.
        </Text>
      </PageContent>
    </section>
  );
}

export default CompanyHistory;

const PageContent = styled.div`
  margin: 20px 50px;
`;

const ImgContainer = styled.div`
  width: 450px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Img = styled.img`
  float: left;
  width: 100%;
  border-radius: 10px;
  border: solid 1px var(--primary-color);
  margin: 0 20px 20px 0;
`;

const Text = styled.p`
  line-height: 25px;
  text-align: justify;
  font-weight: 300;
`;
