import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { Group } from "../../models/group";
import { Product } from "../../models/product";
import getProductsInfo from "../../services/get-products-info";
import BudgetButton from "../budget-button";
import Title from "../title";

function ProductsDetails() {
  const [productCategory, setProductCategory] = useState<string>("");
  const [productGroup, setProductGroup] = useState<string>("");
  const [productDetails, setProductDetails] = useState<Product>({
    id: 0,
    name: "",
    image: "",
    summary: "",
    description: "",
    technicalSpecifications: "",
    characteristics: "",
    additionalInformation: "",
    active: true,
    quantity: 0,
    tags: [],
  });
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { id } = useParams();

  const handleImageClick = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    async function fetchData() {
      const info = await getProductsInfo();

      const categoryIndex = info.categories.findIndex(
        (category: { groups: Group[] }) =>
          category.groups.some((group: { products: Product[] }) =>
            group.products.some((product) => product.id === Number(id)),
          ),
      );

      setProductCategory(info.categories[categoryIndex].name);

      if (categoryIndex !== -1) {
        const groupId = info.categories[categoryIndex].groups.find(
          (group: { products: Product[] }) =>
            group.products.some((product) => product.id === Number(id)),
        );
        setProductGroup(groupId!.name);

        const productId = groupId?.products.find(
          (product: Product) => product.id === Number(id),
        );

        if (productId) {
          setProductDetails(productId);
        }
      }
    }
    fetchData();
  }, [id, productCategory, productGroup]);

  const {
    image,
    name,
    description,
    characteristics,
    technicalSpecifications,
    additionalInformation,
  } = productDetails;

  const characteristcsParagraphs = characteristics.split("*");
  const specificationsParagraphs = technicalSpecifications.split("*");
  const descriptionParagraphs = description.split("*");
  let additionalInfoParagraphs: string[] = [];

  if (additionalInformation !== "") {
    additionalInfoParagraphs = additionalInformation.split("*");
  }

  return (
    <section>
      <Title title={`${productGroup} - ${name}`} />
      <PageContent>
        <ProductDescription>
          <ImgContainer onClick={handleImageClick}>
            <Img src={image} alt="foto do produto" />
            <Overlay />
          </ImgContainer>

          {isModalOpen && (
            <Modal>
              <ModalContent>
                <span
                  role="button"
                  tabIndex={0}
                  onClick={handleImageClick}
                  onKeyDown={(e) => {
                    if (e.key === "Esc") {
                      handleImageClick();
                    }
                  }}
                >
                  X
                </span>
                <Img src={image} alt="foto do produto" />
              </ModalContent>
            </Modal>
          )}

          <TextContainer>
            <div className="category">
              <p>
                <strong>Categoria:</strong>
              </p>
              <p>{productCategory}</p>
            </div>
            {descriptionParagraphs.map((descriptionText) => (
              <p className="description">{descriptionText}</p>
            ))}
            <BudgetButton product={productDetails} />
            <Link to="/produtos">
              <GoBackButton>Voltar para Produtos</GoBackButton>
            </Link>
          </TextContainer>
        </ProductDescription>

        <ProductsSpecification>
          <div className="characteristics">
            <Title title="características" />
            {characteristcsParagraphs.map((characteristic) => (
              <p>{characteristic}</p>
            ))}
          </div>
          <div className="specifications">
            <Title title="especificações técnicas" />
            <ul>
              {specificationsParagraphs.map((specification) => (
                <li>{specification}</li>
              ))}
            </ul>
          </div>
        </ProductsSpecification>

        {additionalInformation !== "" ? (
          <AdditionalInfo className="additional-info">
            <Title title="Informações adicionais" />
            <ul>
              {additionalInfoParagraphs.map((additionalInfo: string) => (
                <li>{additionalInfo}</li>
              ))}
            </ul>
          </AdditionalInfo>
        ) : null}
      </PageContent>
    </section>
  );
}

export default ProductsDetails;

const PageContent = styled.div`
  margin: 20px 50px;

  .title-component {
    margin: 0;
  }

  .title-component h2 {
    font-size: 16px;
    margin: 0;
  }

  hr {
    margin: 0 0 10px 10px;
  }

  @media (max-width: 470px) {
    .title-component h2 {
      letter-spacing: 1px;
    }
  }
`;

const ProductDescription = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 0 30px;
  }
  @media (max-width: 640px) {
    padding: 0;
  }
`;

const ImgContainer = styled.div`
  width: 350px;
  margin: 0 20px 20px 0;
  cursor: pointer;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    margin: 20px 0;
  }
`;

const Img = styled.img`
  width: 100%;
  border-radius: 10px;
  border: solid 1px var(--primary-color);
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--background);
  opacity: 0;

  &:hover {
    opacity: 0.3;
    transition: 0.2s;
  }
`;

const Modal = styled.div`
  position: fixed;
  margin: 20px 50px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  @media (max-width: 576px) {
    margin: 5px;
  }
`;

const ModalContent = styled.div`
  position: relative;
  max-width: 800px;
  max-height: 80%;
  margin: 0 100px;

  span {
    cursor: pointer;
    position: absolute;
    right: 0;
    padding: 5px 10px;
    margin: 5px 10px;
    border-radius: 8px;

    &:hover {
      background-color: var(--hover);
      transition: 0.2s ease-in;
    }
  }

  img {
    width: 100%;
    background-color: #ffffff;
  }

  @media (max-width: 768px) {
    margin: 0;
  }
`;

const TextContainer = styled.div`
  width: 60vw;

  .category {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .category p::first-letter {
    text-transform: uppercase;
  }

  strong {
    font-weight: 500;
  }

  .description {
    line-height: 30px;
    text-align: justify;
  }

  .description:last-of-type {
    margin-bottom: 10px;
  }

  button {
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const GoBackButton = styled.button`
  background-color: var(--text-highlight);
  cursor: pointer;
  color: #ffffff;
  padding: 8px;
  border-radius: 10px;
  font-weight: 300;
  font-size: 12px;
  text-align: center;
  border: none;

  &:hover {
    scale: 1.05;
    transition: 0.2s ease-in-out;
  }
`;

const ProductsSpecification = styled.div`
  padding-top: 20px;
  display: flex;
  text-align: justify;
  justify-content: space-between;
  gap: 25px;

  .characteristics {
    flex: 1;
  }
  .characteristics p {
    line-height: 25px;
    padding: 0 50px 8px 10px;
  }

  .specifications {
    flex: 1;
  }

  .specifications ul {
    list-style: disc inside;
    line-height: 25px;
    padding-left: 20px;
  }

  .specifications ul li::marker {
    font-size: 13px;
  }

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: center;

    .characteristics p {
      padding: 5px 20px;
    }
  }
`;

const AdditionalInfo = styled.div`
  margin-top: 30px;

  ul li {
    line-height: 25px;
    padding: 0 50px 8px 10px;
  }
`;
