import { Link } from "react-router-dom";
import styled from "styled-components";
import map from "../../assets/multitec-location.jpg";
import Title from "../title";

function Map() {
  return (
    <div>
      <Title title="localização" />
      <DivMap>
        <Link
          to="https://www.google.com/maps/place/Multitec/@-29.9129872,-51.1777282,16.77z/data=!4m6!3m5!1s0x9519700771966d07:0x4f351fc2887fa023!8m2!3d-29.9129136!4d-51.178529!16s%2Fg%2F1tfc7sng?entry=ttu"
          target="_blank"
        >
          {" "}
          <img src={map} alt="mapa com localização da empresa" />
        </Link>
      </DivMap>
    </div>
  );
}

export default Map;

const DivMap = styled.div`
  img {
    width: 100%;
    max-height: 500px;
    object-fit: contain;
    margin-top: 20px;
  }
`;
