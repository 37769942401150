import styled from "styled-components";
import gehakaAssistance from "../../assets/assistencia_tecnica_image.jpg";
import logoMultitec from "../../assets/logo512.png";
import soldering from "../../assets/soldering.jpg";
import Title from "../title";

function Services() {
  return (
    <section>
      <Title title="serviços" />
      <PageContent>
        <ImagesContainer>
          <Img src={soldering} alt="quadro multitec" className="soldering" />
          <Img
            src={logoMultitec}
            alt="logo Multitec"
            className="logoMultitec"
          />
          <Img
            src={gehakaAssistance}
            alt="foto assistência técnica Gehaka"
            className="gehakaAssistance"
          />
        </ImagesContainer>

        <List>
          <li>
            <strong>Assistência Técnica</strong> especializada em toda linha de
            equipamentos comercializados pela empresa.
          </li>
          <li>
            <strong>Contrato de Manutenção</strong> preventiva, corretiva,
            Calibração, Qualificação de acordo com a necessidade do cliente.
          </li>
          <li>
            <strong>Agilidade no atendimento</strong> para manutenção corretiva
            nas empresas, contando com Técnicos especializados e peças de
            reposição.
          </li>
          <li>
            <strong>Autorizada pelo Inmetro / RS</strong> a executar manutenção
            em medidores de Umidades de Grãos.{" "}
          </li>
          <li>
            <strong>Autorizada pelo Inmetro/RS</strong> a executar manutenção em
            balanças eletrônicas e mecânicas de casses I, II, III, IV até 392kg.
          </li>
          <li>
            <strong>
              Certificado de Reconhecimento de Competência Técnica de
              Laboratório pela RMRS sob. Nº 18301
            </strong>
            , norma ABNT NBR ISO/IEC 17025:2017, tendo como escopo Calibração em
            Balanças de 0 a 500kg.
          </li>
          <li>
            <strong>Calibração:</strong> Certificado atende aos requisitos de
            Reconhecimento Técnico da Rede Metrológica RS que avaliou a
            competência do laboratório da Multitec e comprovou rastreabilidade
            ao Sistema Internacional de Unidade - SI e aos requisitos da norma
            ABNT NBR ISO IEC 17025.
          </li>
          <li>
            <strong>
              Certificado de Reconhecimento de Competência Técnica de
              Laboratório pela Rede Metrológica RS
            </strong>{" "}
            sob o Nº. 18301.
          </li>
          <br />
          <li>
            <strong>Qualificação</strong> na instalação, operação, funcionamento
            e registros, com fornecimento de protocolo de qualificação conforme
            normas pertinentes.
          </li>
          <li>
            <strong>Técnicos:</strong> Contamos com profissionais treinados nas
            fábricas aptos a prestar serviços rápidos e com qualidade.
          </li>
          <li>
            <strong>Assistência Técnica Exclusiva no RS</strong>, para os
            produtos de fabricação Gehaka, LaborEstabille, Evident/Olympus,
            Shimadzu.
          </li>
          <li>
            <strong>Assistência Técnica</strong> para produtos, General Heater,
            Toledo, Urano, entre outros.
          </li>
          <li>
            <strong>Padrões utilizados:</strong> Todos os padrões utilizados na
            calibração e qualificação, são rastreados por órgãos Acreditados
            pela Rede Brasileira de Calibração (RBC - Inmetro).
          </li>
        </List>
      </PageContent>
    </section>
  );
}

export default Services;

const PageContent = styled.div`
  margin: 20px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImagesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;

  @media (max-width: 768px) {
    justify-content: center;

    .soldering,
    .logoMultitec {
      display: none;
    }
  }
`;

const Img = styled.img`
  width: 350px;
  height: 272px;
  border-radius: 10px;
  border: solid 1px var(--primary-color);
  object-fit: fill;

  @media (max-width: 1024px) {
    width: 270px;
    height: 220px;
  }

  @media (max-width: 768px) {
    margin: 0 30px;
  }
`;

const List = styled.ul`
  list-style: disc inside;
  line-height: 25px;
  li::marker {
    color: var(--text-highlight);
  }
  strong {
    font-weight: 600;
    color: var(--text-highlight);
  }

  @media (max-width: 1024px) {
    text-align: justify;
  }
`;
