import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { INews } from "../../models/news";
import getNewsInfos from "../../services/get-news-infos";
import Title from "../title";

function News() {
  const [news, setNews] = useState<INews[]>([]);

  useEffect(() => {
    async function fetchData() {
      const newsInfo = await getNewsInfos();
      setNews(newsInfo.data);
    }
    fetchData();
  }, []);

  return (
    <section>
      <Title title="notícias" />
      {news.map((newsData) => (
        <NewsContainer>
          <NewsBox>
            <Img src={newsData.image} alt="imagem da notícia" />
            <TextContainer>
              <h3>{newsData.title}</h3>
              <h4>{newsData.date}</h4>
              <p>{newsData.summary}</p>
              <DetailsLink to={`/noticias/${newsData.id}`}>
                {" "}
                Leia mais{" "}
              </DetailsLink>
            </TextContainer>
          </NewsBox>
          <hr />
        </NewsContainer>
      ))}
    </section>
  );
}

export default News;

const NewsContainer = styled.div`
  margin: 20px 50px;
`;

const Img = styled.img`
  width: 180px;
  height: 150px;
  border: solid 1px var(--primary-color);
  border-radius: 10px;
  object-fit: contain;
`;

const NewsBox = styled.div`
  display: flex;
  margin-bottom: 20px;
  gap: 20px;

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: center;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  h3,
  h4 {
    font-style: italic;
  }

  h3 {
    font-weight: 500;
  }

  h4 {
    font-weight: 300;
    font-size: 14px;
    padding-bottom: 15px;
  }

  p {
    text-align: justify;
    line-height: 25px;
    padding-bottom: 15px;
  }
`;
const DetailsLink = styled(Link)`
  background-color: var(--text-highlight);
  color: #ffffff;
  width: 100px;
  padding: 8px;
  border-radius: 15px;
  font-weight: 300;
  font-size: 14px;
  text-align: center;

  &:hover {
    scale: 1.05;
    transition: 0.2s ease-in-out;
  }
`;
