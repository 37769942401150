import styled from "styled-components";
import { Form, FormContainer } from "../../style/form-style";
import Title from "../title";

function RestrictedAccess() {
  return (
    <section>
      <Title title="acesso restrito" />
      <Container>
        <p>
          Por meio deste portal, é possível acessar os certificados emitidos
          pela Multitec utilizando seu login e senha.
        </p>
        <FormContainer>
          <Form
            action="https://www.multitecrs.com.br/certificado/php_incs/valid_login.php"
            method="post"
          >
            <label htmlFor="username">{}</label>
            <input
              type="text"
              id="username"
              name="user"
              placeholder="Login"
              required
            />

            <label htmlFor="password">{}</label>
            <input
              type="password"
              id="password"
              name="pass"
              placeholder="Senha"
              required
            />

            <button type="submit">Acessar</button>
          </Form>
        </FormContainer>
      </Container>
    </section>
  );
}

export default RestrictedAccess;

const Container = styled.div`
  margin: 20px 50px;

  p {
    margin-bottom: 50px;
    line-height: 25px;
    text-align: justify;
  }
`;
