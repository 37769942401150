import axios from "axios";
import { AllCategories } from "../models/allCategories";
import { Category } from "../models/category";
import { Group } from "../models/group";
import { Product } from "../models/product";

type ProductDB = Omit<Product, "id" | "tags" | "active"> & {
  id: string;
  tags: string;
  active: string;
};
type GroupDB = Omit<Group, "id" | "products" | "active"> & {
  id: string;
  active: string;
};
type CategoryDB = Omit<Category, "id" | "groups"> & { id: string };

interface FetchAllResponse {
  products: ProductDB[];
  groups: GroupDB[];
  categories: CategoryDB[];
  productsGroups: { productId: string; groupId: string }[];
  groupsCategories: { groupId: string; categoryId: number }[];
}

function transformProduct(productDB: ProductDB): Product {
  return {
    ...productDB,
    id: Number(productDB.id),
    tags: JSON.parse(productDB.tags),
    active: productDB.active === "1",
  };
}

function transformGroup(groupDB: GroupDB): Group {
  return {
    ...groupDB,
    id: Number(groupDB.id),
    products: [],
    active: groupDB.active === "1",
  };
}

function transformCategory(categoryDB: CategoryDB): Category {
  return {
    ...categoryDB,
    id: Number(categoryDB.id),
    groups: [],
  };
}

async function getProductsInfo(): Promise<AllCategories> {
  const response = await axios.get<FetchAllResponse>(
    "https://multitecrs.com.br/api/fetch_all.php",
  );

  const { data }: { data: FetchAllResponse } = response;

  const productDict: Record<number, Product> = data.products.reduce(
    (acc: Record<number, Product>, productDB) => {
      const product = transformProduct(productDB);
      acc[product.id] = product;
      return acc;
    },
    {},
  );
  const groupDict: Record<number, Group> = data.groups.reduce(
    (acc: Record<number, Group>, groupDB) => {
      const group = transformGroup(groupDB);
      acc[group.id] = group;
      return acc;
    },
    {},
  );
  const categoryDict: Record<number, Category> = data.categories.reduce(
    (acc: Record<number, Category>, categoryDB) => {
      const category = transformCategory(categoryDB);
      acc[category.id] = category;
      return acc;
    },
    {},
  );

  data.productsGroups.forEach(({ productId, groupId }) => {
    groupDict[Number(groupId)].products.push(productDict[Number(productId)]);
  });

  data.groupsCategories.forEach(({ groupId, categoryId }) => {
    categoryDict[Number(categoryId)].groups.push(groupDict[Number(groupId)]);
  });

  return {
    categories: Object.values(categoryDict),
  };
}

export default getProductsInfo;
