import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Category } from "../../models/category";
import getProductsInfo from "../../services/get-products-info";

interface MegaMenuProps {
  isOpen: boolean;
  onClickHandler: () => void;
}

function MegaMenu({ isOpen, onClickHandler }: MegaMenuProps) {
  const [categoryList, setCategoryList] = useState<Category[]>([]);

  useEffect(() => {
    async function fetchData() {
      const renderProducts = await getProductsInfo();
      setCategoryList(
        renderProducts.categories.sort((a, b) => a.name.localeCompare(b.name)),
      );
    }
    fetchData();
  }, []);

  if (!isOpen) return null;

  return (
    <MenuStyle>
      <ul>
        {categoryList.map((category) => (
          <li>
            <Link
              to={`/${category.name.toLowerCase()}`}
              onClick={onClickHandler}
            >
              {category.name}
            </Link>
          </li>
        ))}
      </ul>
    </MenuStyle>
  );
}

export default MegaMenu;

const MenuStyle = styled.div`
  background-color: var(--background-card);
  box-shadow: 1px 1px 15px;
  position: absolute;
  margin-top: 20px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  width: 80vw;
  max-height: 60vh;

  ul {
    padding: 15px;
    column-count: 3;
    overflow: auto;
  }

  li {
    padding: 0 15px;
    line-height: 25px;
  }

  li::first-letter {
    text-transform: uppercase;
  }

  a:hover {
    font-weight: 500;
  }

  @media (max-width: 960px) {
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    width: 60vw;
    margin-top: 10px;
    left: 100%;
    transform: translateX(-102%);
    padding: 0 10px;

    ul {
      column-count: 1;
    }

    li {
      padding: 4px 15px;
    }
  }
`;
