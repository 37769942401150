import { useEffect, useState } from "react";
import styled from "styled-components";
import getProductsInfo from "../../services/get-products-info";
import { Category } from "../../models/category";

interface SelectProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

function Select({ value, onChange }: SelectProps) {
  const [categories, setCategories] = useState<Category[]>([]);

  useEffect(() => {
    async function fetchData() {
      const products = await getProductsInfo();
      const categoryList = products.categories;
      setCategories(categoryList);
    }
    fetchData();
  }, []);

  return (
    <label htmlFor="category">
      <SelectStyle
        name="selectedCategory"
        value={value}
        onChange={(e) => onChange(e)}
      >
        <option value="All">Todas categorias</option>

        {categories.map((category) => (
          <option key={category.name} value={category.name}>
            {category.name}
          </option>
        ))}
      </SelectStyle>
    </label>
  );
}

export default Select;

const SelectStyle = styled.select`
  width: 200px;
  height: 25px;
  border-radius: 15px;
  text-align: center;
  background-color: var(--input);
  border: solid 1px var(--secondary-color);
  outline-color: var(--secondary-color);
  color: var(--background-button);
  text-transform: capitalize;
`;
