import queryString from "query-string";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import removeAccents from "remove-accents";
import styled from "styled-components";
import { Category } from "../../models/category";
import { Product } from "../../models/product";
import getProductsInfo from "../../services/get-products-info";
import BudgetButton from "../budget-button";
import Title from "../title";

function ProductsSearch() {
  const [filteredCategories, setFilteredCategories] = useState<Category[]>([]);
  const [search, setSearch] = useState<string>("");
  const location = useLocation();

  useEffect(() => {
    async function handleSearch() {
      const productsInfo = await getProductsInfo();
      const queryParams = queryString.parse(location.search);
      setSearch((queryParams?.search as string) ?? "");

      const allProducts: Product[] = productsInfo.categories.reduce(
        (acc, category) =>
          acc.concat(category.groups.flatMap((group) => group.products)),
        [] as Product[],
      );

      const searchWords = removeAccents(search.toLowerCase()).split(" ");

      let matchingProducts: Product[] = [];

      if (searchWords.length === 1) {
        matchingProducts = allProducts.filter((product: Product) =>
          searchWords.some((word) =>
            product.tags.some(
              (tag) => removeAccents(tag.toLowerCase()) === word,
            ),
          ),
        );
      } else {
        matchingProducts = allProducts.filter((product: Product) => {
          const matchingTags = searchWords.filter((word) =>
            product.tags.some(
              (tag) => removeAccents(tag.toLowerCase()) === word,
            ),
          );

          return matchingTags.length >= 2;
        });
      }

      if (matchingProducts.length === 0) {
        setFilteredCategories([]);
        return;
      }
      const matchingCategories = productsInfo.categories
        .map((category) => ({
          ...category,
          groups: category.groups
            .map((group) => ({
              ...group,
              products: group.products.filter((product) =>
                matchingProducts.includes(product),
              ),
            }))
            .filter((group) => group.products.length > 0),
        }))
        .filter((category) => category.groups.length > 0);

      setFilteredCategories(matchingCategories);
    }

    handleSearch();
  }, [location.search, search]);

  return (
    <section>
      <Title title={`produtos com '${search}'`} />
      {filteredCategories.length > 0 ? (
        <PageContent>
          {filteredCategories.map((category) => (
            <>
              {category.groups
                .filter((group) => group.active)
                .map((group) => (
                  <>
                    {group.products
                      .filter((product: Product) => product.active)
                      .sort((a: { name: string }, b: { name: string }) =>
                        a.name.localeCompare(b.name),
                      )
                      .map((product: Product) => (
                        <ProductsCard key={product.name}>
                          <Link to={`/produtos/${product.id}`}>
                            <img
                              src={product.image}
                              alt={`foto do produto ${product.name}`}
                            />
                          </Link>
                          <h3>{group.name}</h3>
                          <p className="product-subtitle">{product.name}</p>
                          <p className="summary">{product.summary}</p>
                          <div className="buttons">
                            <BudgetButton product={product} />
                            <DetailsLink to={`/produtos/${product.id}`}>
                              Saiba mais
                            </DetailsLink>
                          </div>
                        </ProductsCard>
                      ))}
                  </>
                ))}
            </>
          ))}
        </PageContent>
      ) : (
        <AlertDiv>
          <p>Produto não encontrado.</p>
          <p>
            Caso não encontre o que está procurando entre em contato conosco{" "}
            <Link to="/contato">clicando aqui.</Link>
          </p>
        </AlertDiv>
      )}
    </section>
  );
}

export default ProductsSearch;

const PageContent = styled.div`
  margin: 20px 50px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 250px));
  grid-auto-rows: 475px;
  justify-content: space-between;
  gap: 30px;

  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const ProductsCard = styled.div`
  background-color: var(--background-card);
  border: solid 1px #000000;
  border-radius: 10px;
  width: 250px;
  height: 475px;
  display: flex;
  flex-direction: column;
  padding: 15px;

  img {
    width: 100%;
    height: 220px;
    margin: 5px;
    object-fit: cover;
    align-self: center;
    border-radius: 10px;
    background-color: #ffffff;
  }

  h3 {
    color: var(--text-highlight);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
  }

  .product-subtitle {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
  }

  .summary {
    font-size: 14px;
    font-weight: 300;
    text-align: justify;
    margin: auto 0;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: auto;

    button {
      font-size: 12px;
    }
  }
`;

const DetailsLink = styled(Link)`
  background-color: var(--text-highlight);
  color: #ffffff;
  width: 80px;
  padding: 8px 5px;
  border-radius: 8px;
  font-weight: 300;
  font-size: 12px;
  text-align: center;

  &:hover {
    scale: 1.05;
    transition: 0.2s ease-in-out;
  }
`;

const AlertDiv = styled.div`
  background-color: var(--text-highlight);
  position: relative;
  text-align: center;
  color: #f8d7da;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;

  a {
    text-decoration: underline;
  }
`;
