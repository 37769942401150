import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { Category } from "../../models/category";
import { Product } from "../../models/product";
import getProductsInfo from "../../services/get-products-info";
import BudgetButton from "../budget-button";
import Title from "../title";

function CategoryComponent() {
  const [categoryList, setCategoryList] = useState<Category[]>([]);
  const { category } = useParams<string>();

  useEffect(() => {
    async function fetchData() {
      const renderProducts = await getProductsInfo();
      setCategoryList(renderProducts.categories);
    }
    fetchData();
  }, []);

  const fiilteredProducts = categoryList.filter(
    (categories) => categories.name.toLowerCase() === category?.toLowerCase(),
  );

  return (
    <section>
      <Title title={category!} />
      <PageContent>
        {fiilteredProducts.map((categories) => (
          <ProductsContainer>
            <Products>
              {categories.groups
                .filter((group) => group.active)
                .map((group) => (
                  <>
                    {group.products
                      .filter((product: Product) => product.active)
                      .sort((a: { name: string }, b: { name: string }) =>
                        a.name.localeCompare(b.name),
                      )
                      .map((product: Product) => (
                        <ProductsCard key={product.name}>
                          <Link to={`/produtos/${product.id}`}>
                            <img
                              src={product.image}
                              alt={`foto do produto ${product.name}`}
                            />
                          </Link>
                          <h3>{group.name}</h3>
                          <p className="product-subtitle">{product.name}</p>
                          <p className="summary">{product.summary}</p>
                          <div className="buttons">
                            <BudgetButton product={product} />
                            <DetailsLink to={`/produtos/${product.id}`}>
                              Saiba mais
                            </DetailsLink>
                          </div>
                        </ProductsCard>
                      ))}
                  </>
                ))}
            </Products>
          </ProductsContainer>
        ))}
      </PageContent>
    </section>
  );
}

export default CategoryComponent;

const PageContent = styled.div`
  margin: 20px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProductsContainer = styled.div`
  width: 100%;

  .title-component {
    margin: 0;
    margin-top: 20px;
  }

  .title-component h2 {
    font-size: 16px;
    margin: 0;
  }

  hr {
    margin: 0 0 10px 10px;
  }
`;

const Products = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 250px));
  grid-auto-rows: 475px;
  justify-content: space-between;
  gap: 30px;

  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const ProductsCard = styled.div`
  background-color: var(--background-card);
  border: solid 1px #000000;
  border-radius: 10px;
  width: 250px;
  min-height: 475px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin-bottom: 30px;

  img {
    width: 100%;
    height: 220px;
    margin: 5px;
    object-fit: contain;
    align-self: center;
    border-radius: 10px;
    background-color: #ffffff;
  }

  h3 {
    color: var(--text-highlight);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
  }

  .product-subtitle {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
  }

  .summary {
    font-size: 14px;
    font-weight: 300;
    text-align: justify;
    margin: auto 0;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
  }
`;

const DetailsLink = styled(Link)`
  background-color: var(--text-highlight);
  color: #ffffff;
  width: 80px;
  padding: 8px 5px;
  border-radius: 8px;
  font-weight: 300;
  font-size: 12px;
  text-align: center;

  &:hover {
    scale: 1.05;
    transition: 0.2s ease-in-out;
  }
`;
