import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { produce } from "immer";
import { Product } from "../../models/product";

const CART = "cart";
export interface CartState {
  cartProducts: Product[];
}

export interface ProductToAdd {
  id: number;
}

const initialState: CartState = {
  cartProducts: JSON.parse(localStorage.getItem(CART) ?? "[]"),
};

const cartSlice = createSlice({
  name: CART,
  initialState,
  reducers: {
    addProductToCart: (
      state: CartState,
      action: PayloadAction<{ product: ProductToAdd }>,
    ) =>
      produce(state, () => {
        const existingProduct = state.cartProducts.find(
          (product) => product.id === action.payload.product.id,
        );

        if (existingProduct) {
          existingProduct.quantity += 1;
        } else {
          const newProduct = {
            ...action.payload.product,
            quantity: 1,
          } as Product;
          state.cartProducts.push(newProduct);
        }

        localStorage.setItem(CART, JSON.stringify(state.cartProducts));
      }),

    removeProductFromCart: (state, action: PayloadAction<number>) =>
      produce(state, () => {
        state.cartProducts = state.cartProducts.filter(
          (product) => product.id !== action.payload,
        );

        localStorage.setItem(CART, JSON.stringify(state.cartProducts));
      }),

    decreaseQuantity: (state, action: PayloadAction<number>) =>
      produce(state, () => {
        const existingProduct = state.cartProducts.find(
          (product) => product.id === action.payload,
        );

        if (existingProduct && existingProduct.quantity > 1) {
          existingProduct.quantity -= 1;
        } else {
          state.cartProducts = state.cartProducts.filter(
            (product) => product.id !== action.payload,
          );
        }

        localStorage.setItem(CART, JSON.stringify(state.cartProducts));
      }),
    clearProductsFromCart: (state) =>
      produce(state, () => {
        state.cartProducts = [];

        localStorage.setItem(CART, JSON.stringify(state.cartProducts));
      }),
  },
});

export const {
  addProductToCart,
  removeProductFromCart,
  decreaseQuantity,
  clearProductsFromCart,
} = cartSlice.actions;
export default cartSlice.reducer;
