import { useState } from "react";
import { FaCartShopping } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Product } from "../../models/product";
import { addProductToCart } from "../../store/reducers/cartReducers";

interface BudgetButtonProps {
  product: Product;
}

function BudgetButton(product: BudgetButtonProps) {
  const dispatch = useDispatch();
  const [isDisplayOpen, setIsDisplayOpen] = useState<boolean>(false);

  const handleAddToCart = () => {
    dispatch(addProductToCart(product));
  };

  const handleBudgetDisplay = () => {
    setIsDisplayOpen(!isDisplayOpen);
  };

  return (
    <>
      <BudgetButtonStyle
        type="button"
        onClick={() => {
          handleAddToCart();
          handleBudgetDisplay();
        }}
      >
        Solicitar orçamento <FaCartShopping />
      </BudgetButtonStyle>
      {isDisplayOpen && (
        <Display>
          <DisplayContent>
            <p>O que você gostaria de fazer?</p>
            <Buttons>
              <button
                className="keep-looking"
                type="button"
                onClick={handleBudgetDisplay}
              >
                Continuar olhando os produtos
              </button>
              <Link to="/orcamento">Finalizar orçamento</Link>
            </Buttons>
          </DisplayContent>
        </Display>
      )}
    </>
  );
}

export default BudgetButton;

const BudgetButtonStyle = styled.button`
  cursor: pointer;
  border: none;
  background-color: var(--background-button);
  color: var(--input);
  padding: 8px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 12px;

  &:hover {
    background-color: var(--hover);
    transition: 0.2s ease-in;
  }
`;

const Display = styled.div`
  position: fixed;
  margin: 20px 50px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const DisplayContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  border-radius: 10px;
  border: solid 1px var(--primary-color);
  background-color: var(--background);
  color: var(--primary-color);
  min-width: 30vw;
  height: 30vh;
  font-size: 20px;
  text-align: center;
  p {
    font-weight: 500;
  }
`;
const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 0;
  gap: 15px;
  .keep-looking,
  a {
    cursor: pointer;
    border: none;
    background-color: var(--background-button);
    color: var(--input);
    padding: 8px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;

    &:hover {
      background-color: var(--hover);
      transition: 0.2s ease-in;
    }
  }
`;
