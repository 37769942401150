import { Provider } from "react-redux";
import "./App.css";
import AppRoutes from "./routes";
import store from "./store";
import { GlobalStyle } from "./style/global-style";

function App() {
  return (
    <Provider store={store}>
      <GlobalStyle />
      <AppRoutes />
    </Provider>
  );
}

export default App;
